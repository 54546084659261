<template>
  <div class="index-module">
    <a-card title="常用功能">
      <a-card-grid class="commonly-item" ><router-link to="/setting/system/site">设置</router-link></a-card-grid>
      <a-card-grid class="commonly-item" ><router-link to="/cms/content/list">内容</router-link></a-card-grid>
      <a-card-grid class="commonly-item" ><router-link to="/manage/system/user">管理</router-link></a-card-grid>
    </a-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import * as api from "@/addons/manage/api";
import { createRouteByList } from "@/router/addon";
export default defineComponent({
  components: {},
  setup() {
    // 获取本地固定路由下所有路由信息
    const menus = createRouteByList(true);
    // console.log(menus);

    const syncNav = () => {
      api
          .sync_nav({
            data: menus,
          })
          .then((res) => {
            console.log(res);
          });
    };

    return { syncNav };
  },
});
</script>
<style scoped>
.index-module {
  margin-bottom: 24px;
}
.commonly-item {
  width: 20%;
  text-align: center;
}
.order-status {
  padding: 40px 0;
  background-color: #fff;
  text-align: center;
}
.order-status:hover {
  background-color: #cdcdcd;
}
</style>
